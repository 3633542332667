import React from "react";

// External

// Internal
import Layout from "../../components/layout";
import SEO from "../../components/SEO";
import { CondexoPropertyManagementProjectSection } from "../../containers/projects";

const CondexoPropertyManagementProjectPage = () => {
  return (
    <Layout>
      <SEO
        title="Condexo Property Management - Projects"
        description="Reducing Landlord's burden of handling properties using Property Management Web App | Real Estate Industry | Year: 2019-2021 | Javascript, React, Stripe"
      />
      <CondexoPropertyManagementProjectSection />
    </Layout>
  );
};

export default CondexoPropertyManagementProjectPage;
